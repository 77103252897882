<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">會員管理</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5 h-full">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <!-- <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
            >
              搜尋
            </button>
          </div>
        </div> -->
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <cf-data-grid
          ref="grid"
          :options="gridOptions"
          :show-border="true"
        ></cf-data-grid>
      </div>

      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <!-- <detail :id="rowId" ref="detail"></detail> -->
        <!-- <cf-data-grid :options="options" :show-border="true"></cf-data-grid> -->
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
// import DxButton from "devextreme-vue/button";
// import { DxDataGrid } from "devextreme-vue/data-grid";

import CloudFun, { defineComponent, ref, reactive } from "@cloudfun/core";
import createDataSource from "@/utils/devextreme";
import { DxDataSources } from "../../services/data-source-service";
// import Detail from "./Detail.vue";
import CustomStore from "devextreme/data/custom_store";
export default defineComponent({
  components: {},
  setup() {
    const grid = ref({});
    const rowId = ref(0);
    const detail = ref({});
    const dataSource = ref([]);
    const defaultDataSourceFilter = [];
    const model = CloudFun.current?.model;
    const sexType = model?.enums.Sex;

    const permission = reactive({
      isExport: model?.user.Permissions.includes("OrderExport"),
      isDelete: model?.user.Permissions.includes("OrderDelete"),
    });
    const generateQueryConditions = (startDate, endDate) => {
      const conditions = [defaultDataSourceFilter];
      if (startDate) {
        conditions.push(["CreatedTime", ">=", startDate]);
      }
      if (endDate) {
        conditions.push(["CreatedTime", "<", endDate]);
      }

      return conditions;
    };

    const MemberDataSource = DxDataSources.MemberDataSource({
      filterExpr: defaultDataSourceFilter,
    });

    const gridColumns = [
      // { dataField: "Id", label: { text: "Id" } },
      { dataField: "Profile.Name", label: { text: "姓名" } },
      { dataField: "Nickname", label: { text: "簡稱" } },
      { dataField: "Profile.Email", label: { text: "Email" } },
      {
        dataField: "Profile.Sex",
        label: { text: "性別" },
        // calculateDisplayValue: (value) => {
        //   return value.Profile.Sex == 0 ? "女":"男";
        // },        
        lookupOptions: {
          dataSource: sexType,
          displayExpr: "Name",
          valueExpr: "Value",
        },
      },
      {
        dataField: "CreatedTime",
        label: { text: "會員加入時間" },
        sortOrder: "desc",
        dataType: "date",
        allowEditing: false,
      },      
      {
        dataField: "Profile.BirthDate",
        label: { text: "生日" },
        dataType: "date",
      },
    ];

    const gridOptions = {
      keyFieldName: "Id",
      dataSource: MemberDataSource.dataSource,
      canDelete: permission.isDelete,
      canCreate: false,
      canEdit: true,
      height: "auto",
      columns: gridColumns,
      onRowClick: onRowClick,
      focusedEnabled: false,
      remoteOperations: true,
      // summarySettings: {
      //   items: [
      //     {
      //       type: "total",
      //       column: "Amount",
      //       summaryType: "sum",
      //       skipEmptyValues: true,
      //       displayFormat: "小計: {0}",
      //       valueFormat: { type: "currency", precision: 0 },
      //     },
      //   ],
      // },
      // onInitialized: this.onGridInitialized,
      // onCellPrepared: this.onCellPrepared,
      // onContextMenuPreparing: this.onContextMenuPreparing,
      // stateSave: true,
      // stateStoring: {
      //   enabled: true,
      //   storageKey: this.$route.name,
      // },
    };

    function onRowClick({ data }) {
      // this.loadData(key);
      // debugger;
      rowId.value = data.Id;
      console.log("detail", detail);
      detail.value.reload(data.Items);
    }

    console.log(sexType)

    return {
      grid,
      rowId,
      defaultDataSourceFilter,
      generateQueryConditions,
      MemberDataSource,
      gridOptions,
      onRowClick,
      permission,
      // detail,
      dataSource,
      sexType,
    };
  },
  methods: {},
});
</script>

<style scoped></style>
